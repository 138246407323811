import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";

import NewsTemplate from "../../templates/news/NewsTemplate";
import NewsLink from "../../components/news/NewsLink";
import HtmlContent from "../../components/helpers/HtmlContent";
import ChevronLink from "../../components/common/ChevronLink";
import H1WithLink from "../../components/common/H1WithLink";

import * as classes from "./{News.slug}.module.scss";

export default function NewsPage({data}) {

    const {title, date, timestamp, image, imageAlt, parent: {html}} = data["news"];

    const seeAlsoEdges = data["allNews"]["edges"];

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <ChevronLink
                className={classes.backLink}
                chevronSide="LEFT"
                to="/news">Вернуться ко всем новостям</ChevronLink>
            <NewsTemplate
                title={title}
                timestamp={timestamp}
                date={date}
                image={image}
                imageAlt={imageAlt}>
                <HtmlContent content={html} />
            </NewsTemplate>
            {!!seeAlsoEdges?.length &&
                <section className={classes.seeAlsoContainer}>
                    <H1WithLink
                        linkText="Посмотреть все новости"
                        linkTo="/news">Смотрите также</H1WithLink>
                    <div className={classes.seeAlsoLinks}>
                        {seeAlsoEdges?.map(({node}) =>
                            <NewsLink
                                key={node.id}
                                className={classes.seeAlsoLink}
                                {...node} />)}
                    </div>
                </section>}
        </>
    );

}

export const query = graphql`
    query($id: String!) {
        news(id: {eq: $id}) {
            title
            date: timestamp(formatString: "DD MMMM YYYY", locale: "ru")
            timestamp
            image {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 1160, height: 652)
                }            
            }
            imageAlt
            parent {
                ...on MarkdownRemark {
                    html
                }
            }
        }
        allNews(
            filter: {id: {ne: $id}}
            sort: {fields: [timestamp], order: DESC}
            limit: 3
        ) {
            edges {
                node {
                    ...NewsLink
                }
            }
        }
    }
`;
