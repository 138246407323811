import React from "react";
import PropTypes from "prop-types";

import PreviewCompatibleImage from "../../components/helpers/PreviewCompatibleImage";

import * as classes from "./NewsTemplate.module.scss";

export default function NewsTemplate({title, date, timestamp, image, imageAlt, children}) {
    return (
        <article className={classes.container}>
            <h1>{title}</h1>
            <p className={classes.date}><time dateTime={timestamp}>{date}</time></p>
            <PreviewCompatibleImage
                className={classes.image}
                image={image}
                alt={imageAlt} />
            {children}
        </article>
    );
}

NewsTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    imageAlt: PropTypes.string.isRequired
};
